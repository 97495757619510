// ./src/components/ui/SocialIcons.js

import React from 'react';

const SocialIcons = () => {
    return (
        <div>
            {/* Para dispositivos móviles y tablets */}
            <div className="flex flex-col space-y-4 fixed top-1/2 right-4 transform -translate-y-1/2 md:hidden z-50">
                <a
                    href="https://chat.whatsapp.com/DWPZWe4mJhv6d4ZNQCCrlO"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 justify-center bg-green-400 hover:bg-green-600 text-black hover:text-white rounded-full shadow-lg p-3 transition duration-300 opacity-50"
                >
                    <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fillRule="evenodd" transform="translate(3 3)">
                            <path d="m8 13.5172414c4.418278 0 8-3.0259376 8-6.75862071 0-3.73268314-3.581722-6.75862069-8-6.75862069s-8 3.02593755-8 6.75862069c0 1.45741942.5460328 2.80709561 1.47469581 3.91098161l-.97469581 4.5803977 3.91607376-2.4472652c1.07810761.4571647 2.29544433.7145066 3.58392624.7145066z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="m7.99884033 8c.5 0 1-.5 1-1s-.5-1-1-1-.99884033.5-.99884033 1 .49884033 1 .99884033 1zm-4 0c.5 0 1-.5 1-1s-.5-1-1-1-.99884033.5-.99884033 1 .49884033 1 .99884033 1zm8.00057947 0c.5002901 0 1.0005802-.5 1.0005802-1s-.5002901-1-1.0005802-1-.9994198.5-.9994198 1 .4991297 1 .9994198 1z" fill="currentColor" />
                        </g>
                    </svg>
                </a>
                <a
                    href="https://twitter.com/epicevils"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 justify-center bg-blue-400 hover:bg-blue-600 text-black hover:text-white rounded-full shadow-lg p-3 transition duration-300 opacity-50"
                >
                    <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
                            <path d="m13.5 5.5-2 10" />
                            <path d="m9.5 5.5-2 10" />
                            <path d="m6.5 8.5h9" />
                            <path d="m5.5 12.5h9" />
                        </g>
                    </svg>
                </a>
            </div>

            {/* Para pantallas grandes (ordenadores) */}
            <div className="hidden md:flex space-x-4 fixed bottom-4 right-4 transform -translate-x-1/2 z-50">
                <a
                    href="https://chat.whatsapp.com/DWPZWe4mJhv6d4ZNQCCrlO"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 justify-center bg-green-400 hover:bg-green-600 text-black hover:text-white rounded-full shadow-lg p-3 transition duration-300 opacity-50"
                >
                    <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fillRule="evenodd" transform="translate(3 3)">
                            <path d="m8 13.5172414c4.418278 0 8-3.0259376 8-6.75862071 0-3.73268314-3.581722-6.75862069-8-6.75862069s-8 3.02593755-8 6.75862069c0 1.45741942.5460328 2.80709561 1.47469581 3.91098161l-.97469581 4.5803977 3.91607376-2.4472652c1.07810761.4571647 2.29544433.7145066 3.58392624.7145066z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="m7.99884033 8c.5 0 1-.5 1-1s-.5-1-1-1-.99884033.5-.99884033 1 .49884033 1 .99884033 1zm-4 0c.5 0 1-.5 1-1s-.5-1-1-1-.99884033.5-.99884033 1 .49884033 1 .99884033 1zm8.00057947 0c.5002901 0 1.0005802-.5 1.0005802-1s-.5002901-1-1.0005802-1-.9994198.5-.9994198 1 .4991297 1 .9994198 1z" fill="currentColor" />
                        </g>
                    </svg>
                </a>
                <a
                    href="https://twitter.com/epicevils"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 justify-center bg-blue-400 hover:bg-blue-600 text-black hover:text-white rounded-full shadow-lg p-3 transition duration-300 opacity-50"
                >
                    <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
                            <path d="m13.5 5.5-2 10" />
                            <path d="m9.5 5.5-2 10" />
                            <path d="m6.5 8.5h9" />
                            <path d="m5.5 12.5h9" />
                        </g>
                    </svg>
                </a>
            </div>
        </div>
    );
};

export default SocialIcons;
