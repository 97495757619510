// ./src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Helmet } from 'react-helmet';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Helmet>
      <script defer src="https://umami-uo044sw.app-palma.teide.app/script.js" data-website-id="a9e55e8f-3c30-4df2-bc21-fd6d2a2aca69"></script>
    </Helmet>
    <App />
  </React.StrictMode>
);
