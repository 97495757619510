/* ./src/pages/home.js */

import React from 'react';
import { CardProvider } from '../components/objects/CardContext';
import Trading from '../components/actions/Trading';

export default function App() {
    return (
        <CardProvider>
            <Trading />
        </CardProvider>
    );
}
