// ./src/components/ui/Footer.js

import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-black text-blue-300 ledscreen-bold p-4 border-t-4 border-white border-dotted text-center">
            <p>TCGSE &copy; {new Date().getFullYear()} Pencil Works, LLC.</p>
            <p>Trading Card Game Stock Exchange</p>
            <p>All rights reserved.</p>
        </footer>
    );
}

export default Footer;