import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import pb from "../../utils/pocketbase";

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const authData = Cookies.get("pb_auth");
  const isAuthenticated = !!authData;

  const handleLogout = () => {
    pb.authStore.clear();
    Cookies.remove("pb_auth");
    navigate("/auth");
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-black ledscreen text-white p-4 border-b-4 border-white border-dotted">
      <div className="flex justify-between items-center md:hidden">
        <h1 className="sm:text-sm md:text-xl font-bold text-center text-green-400 mr-2">
          TCGSE.com | TCG Stock Exchange
        </h1>
        <button
          onClick={toggleMenu}
          className="text-white focus:outline-none bg-blue-900 p-2 rounded-[14px] hover:bg-blue-900 transition-all duration-300 justify-center items-center flex"
        >
          {isOpen ? (
            <svg
              height="21"
              viewBox="0 0 21 21"
              width="21"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                fill-rule="evenodd"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                transform="translate(5 5)"
              >
                <path d="m10.5 10.5-10-10z" />
                <path d="m10.5.5-10 10" />
              </g>
            </svg>
          ) : (
            <svg
              height="21"
              viewBox="0 0 21 21"
              width="21"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                fill-rule="evenodd"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="m4.5 6.5h12" />
                <path d="m4.498 10.5h11.997" />
                <path d="m4.5 14.5h11.995" />
              </g>
            </svg>
          )}
        </button>
      </div>
      <ul
        className={`flex-col md:flex md:flex-row gap-4 justify-center ${
          isOpen ? "flex" : "hidden"
        } md:flex`}
      >
        <div className="flex flex-col md:flex-row items-center gap-4 text-lg justify-center space-y-4 md:space-y-0 md:space-x-4">
          {/* {pages.map((page, index) => (
            <li
              key={index}
              className="bg-blue-500 px-3 py-2 rounded-[14px] hover:bg-[#CACACA] transition-all duration-300"
            >
              <a
                className={`hover:underline ${page.textColor}`}
                href={page.url}
              >
                {page.name}
              </a>
            </li>
          ))}
 */}
          {/* bg-blue-500  */}
          {isAuthenticated ? (
            <div class="hidden">
              {/* <> */}
              {/* <li className="bg-blue-500 px-3 py-2 rounded-[14px] hover:bg-[#CACACA] transition-all duration-300">
                                <a href="/dashboard" className="text-white hover:text-blue-600">Dashboard</a>
                            </li> */}
              <li className="bg-orange-500 px-3 py-2 rounded-[14px] hover:bg-[#CACACA] transition-all duration-300">
                <button
                  onClick={handleLogout}
                  className="text-white hover:text-red-600"
                >
                  Logout
                </button>
              </li>
              {/* </> */}
            </div>
          ) : (
            /* Logins desactivados */
            <div class="hidden">
              {/*  <li className="bg-blue-500 px-3 py-2 rounded-[14px] hover:bg-[#CACACA] transition-all duration-300">
                                <a href="/auth" className="text-white hover:text-blue-600">Login / Signup</a>
                            </li> */}
            </div>
          )}
          {sponsors.map((sponsor, index) => (
            <li key={index} className="p-4">
              {" "}
              {/* bg-[#ffffff40]  */}
              <a
                className={`hover:underline ${sponsor.textColor}`}
                href={sponsor.url}
                target="_blank"
                rel="noreferrer"
              >
                {sponsor.name}
              </a>
            </li>
          ))}
        </div>
      </ul>
    </nav>
  );
};

const sponsors = [
  {
    name: "DeckMaker",
    url: "https://deckmaker.tcgse.com",
    textColor:
      "text-white hover:text-black bg-orange-500 rounded-[18px] px-3 py-2",
  },
  {
    name: "EpicEvils",
    url: "https://www.epicevils.com",
    textColor:
      "text-white hover:text-black bg-red-500 rounded-[18px] px-3 py-2",
  },
];
/* 
const pages = [
  {
    name: "Home",
    url: "/",
    textColor: "text-white hover:text-blue-600",
  },
  {
    name: "Events",
    url: "/events",
    textColor: "text-white hover:text-blue-600",
  },
]; */

export default Menu;
