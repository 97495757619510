// ./src/App.js

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/home";
import SocialIcons from "./components/objects/SocialIcons";
/* import Banner from "./components/ui/Banner/Banner"; */
import Menu from "./components/ui/Menu/Menu";
import Footer from "./components/ui/Section/Footer";
import Card from "./components/actions/Card";
/* import Events from "./components/actions/Events"; */
import Trading from "./components/actions/Trading";
import Share from "./components/actions/Share";
import AuthPage from "./components/auth/AuthPage";
import Dashboard from "./components/Views/Dashboard";
import { CardProvider } from "./components/objects/CardContext";
import Cookies from "js-cookie";
import pb from "./components/utils/pocketbase";
import "./style.css";

const App = () => {
  return (
    <CardProvider>
      <Router>
        <div className="flex flex-col min-h-screen bg-black text-white">
          {/* <Banner /> */}
          <Menu />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/share/:cards" element={<Share />} />
            <Route
              path="/card/:set/:setCard/:setCardUnderscore"
              element={<Card />}
            />
            <Route path="/trading" element={<Trading />} />
            {/* <Route path="/events" element={<Events />} /> */}
            <Route path="/auth" element={<AuthPage />} />
            <Route
              path="/dashboard"
              element={<PrivateRoute component={Dashboard} />}
            />
          </Routes>
          <SocialIcons />
          <Footer />
        </div>
      </Router>
    </CardProvider>
  );
};

// Componente para proteger rutas privadas
const PrivateRoute = ({ component: Component }) => {
  const authData = Cookies.get("pb_auth");

  if (!authData) {
    return <Navigate to="/auth" />;
  }

  const parsedAuthData = JSON.parse(authData);
  pb.authStore.save(parsedAuthData.token, parsedAuthData.model);

  return <Component />;
};

export default App;
